import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Link,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
} from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import MomoKarmaIcon from "../Icons/AppIcons/MomoKarmaIcon.svg";
import AppKarmaIcon from '../Icons/AppIcons/AppKarmaIcon.svg'
import CashKarmaIcon from '../Icons/AppIcons/CashKarmaIcon.svg'
import { useNavigate } from 'react-router-dom'

export default function NavbarComponent({ selectedPage, setSelectedPage, appRef }) {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const menuItems = [
    {
      value: 'About',
      key: '#landing_page',
    },
    // {
    //   value: 'About',
    //   key: '#about_page',
    // },
    {
      value: 'Affiliate Program',
      key: '#affiliate_program_page',
    },
    {
      value: 'Contact',
      key: '#contact_page',
    },
  ]

  /**
   * Add Your Apps
   */
  const Apps = [
    {
      key: 'AppKarma',
      label: 'AppKarma',
      icon: <Image src={AppKarmaIcon} width={'24'} height={'24'} />,
      ref: appRef[0],
    },
    {
      key: 'CashKarma',
      label: 'CashKarma',
      icon: <Image src={CashKarmaIcon} width={'24'} height={'24'} />,
      ref: appRef[1],
    },
  ]
  useEffect(() => {

  }, [isMenuOpen])

  return (
    <Navbar
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      className="lg:px-8 px-0 text-black py-2 bg-transparent lg:container mx-auto"
      maxWidth={'full'}
    >
      <NavbarContent>
        <NavbarMenuToggle
          aria-label={!isMenuOpen ? 'Close menu' : 'Open menu'}
          className="sm:hidden"
        />
        <NavbarBrand className="">
          <div className="flex items-center gap-2">
            <div className=""><Image src={MomoKarmaIcon} width={'36'} height={'36'} /></div> <div className='font-bold text-xl' style={{ fontFamily: 'Mulish' }}>MomoKarma</div>
          </div>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent
        className="hidden sm:flex text-black"
        justify="center"
      >
        {menuItems.map((item, key) => (
          <NavbarItem
            key={key}
            isActive={selectedPage === item.key}
            className="text-black"
          >
            <Button className='text-black text-base bg-transparent hover:bg-[#0e75ff] hover:text-white' onClick={() => {
              setSelectedPage(item.key)
              navigate(item.key)
            }}>
              {item.value}
            </Button>
            {/* <Link
              href={item.key}
              className={item.key ? 'text-black' : 'text-foreground'}
              onClick={() => {
                setSelectedPage(item.key)
              }}
            >
              {item.value}
            </Link> */}
          </NavbarItem>
        ))}
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem className="flex">
          <Dropdown onOpenChange={() => setIsDropdownOpen((pre) => !pre)}>
            <DropdownTrigger>
              <Button className="bg-[#147AFF]" radius="none">
                <div className="flex gap-2 items-center">
                  <div className="text-white text-base" style={{
                    fontFamily: "mulish"
                  }} >Download App</div>{' '}
                  <div className="text-white pt-[1px]">
                    {isDropdownOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m4.5 15.75 7.5-7.5 7.5 7.5"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m19.5 8.25-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Dynamic Actions" items={Apps}>
              {(item, index) => (
                <DropdownItem key={index} onClick={() => item.ref.current.scrollIntoView({ behavior: 'smooth' })} >
                  <div className="flex items-center">
                    {/* <Link className="flex items-center" href={item.link}> */}
                    <div>{item.icon}</div>
                    <div className="ms-1">{item.label}</div>
                    {/* </Link> */}
                  </div>
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </NavbarItem>
      </NavbarContent>
      <NavbarMenu className="">
        {menuItems.map((item, index) => (
          <NavbarMenuItem key={`${item.key}-${index}`} isActive={item.key === selectedPage}>
            <Link className="w-full pt-4" href={`${item.key}`} size="lg" onClick={() => setIsMenuOpen(pre => !pre)}>
              {item.value}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </Navbar>
  )
}
