import { Button, Input } from "@nextui-org/react";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { EyeSlashFilledIcon } from "../component/Icons/EyeSlashFilledIcon";
import { EyeFilledIcon } from "../component/Icons/EyeFilledIcon";
import MintKarmaLogo from "../component/Icons/MintKarma";
import { useNavigate } from "react-router-dom";

function Reset() {
  const [isVisible, setIsVisible] = React.useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const navigate = useNavigate();
  const passwordErrorNotify = () =>
    toast.error("Password Not Matched!!!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const successfullyResetNotify = () => {
    toast.success("Successfully Reset your password!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const [resetPassword, setResetPassword] = useState({
    password: "",
    confirmPassword: "",
  });

  const toggleVisibility = () => setIsVisible(!isVisible);

  const passwordMatch = () => {
    console.log(resetPassword);
    const matchedPassword =
      resetPassword.password !== resetPassword.confirmPassword;
    if (matchedPassword) {
      passwordErrorNotify();
    } else {
      successfullyResetNotify();
      navigate("/reset-confirmation");
    }
    setIsInvalid(matchedPassword);
  };

  return (
    <div
      className="h-screen flex flex-col items-center justify-center overflow-scroll"
      style={{
        backgroundColor: "#111312",
        fontFamily: "Fira Sans, sans-serif",
      }}
    >
      <div className="mb-4">
        <MintKarmaLogo />
      </div>
      <div
        className="lg:w-1/3 md:w-1/2 w-96 flex items-center justify-center p-4 rounded-lg"
        style={{
          backgroundColor: "#636363",
        }}
      >
        <div className="w-full">
          <div className="text-center p-4 text-white">
            <div className="font-bold text-2xl">Reset Yout Password</div>
            <div className="text-sm">Enter New Password</div>
          </div>

          <div className="">
            <Input
              className="pb-4"
              label={<span className="text-white">New Password</span>}
              placeholder="New password "
              labelPlacement="outside"
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={toggleVisibility}
                >
                  {isVisible ? (
                    <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                  ) : (
                    <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                  )}
                </button>
              }
              type={isVisible ? "text" : "password"}
              color={isInvalid ? "danger" : "black"}
              // errorMessage={isInvalid && "Password dit not Metch"}
              defaultValue={resetPassword.password}
              onChange={(e) =>
                setResetPassword({ ...resetPassword, password: e.target.value })
              }
            />
            <Input
              className="pb-4"
              label={<span className="text-white">Confirm Password</span>}
              placeholder="Re-type password "
              labelPlacement="outside"
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={toggleVisibility}
                >
                  {isVisible ? (
                    <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                  ) : (
                    <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                  )}
                </button>
              }
              type={isVisible ? "text" : "password"}
              color={isInvalid ? "danger" : "black"}
              errorMessage={isInvalid && "Password is not matched"}
              defaultValue={resetPassword.resetPassword}
              onChange={(e) =>
                setResetPassword({
                  ...resetPassword,
                  confirmPassword: e.target.value,
                })
              }
            />

            <Button
              className="w-full mb-4"
              style={{ backgroundColor: "#B1EDC4" }}
              onClick={() => passwordMatch()}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reset;
