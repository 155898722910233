import { createBrowserRouter } from "react-router-dom";
import MomoKarmaPage from "../pages/MomoKarmaPage";
import Reset from "../pages/Reset";
import ResetConfirmation from "../pages/ResetConfirmation";
import Landing from "../pages/Landing";

export default createBrowserRouter([
  {
    path: "/",
    element: <MomoKarmaPage />,
  },
  {
    path: "/reset",
    element: <Reset />,
  },
  {
    path: "/reset-confirmation",
    element: <ResetConfirmation />,
  },
  {
    path: "/landing",
    element: <Landing />,
  },
]);
