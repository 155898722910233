import { Button } from "@nextui-org/react";
import React from "react";
import MintKarmaLogo from "../component/Icons/MintKarma";
import MintKarmaPasswordResetLogo from "../component/Icons/PasswordResetIcon";

export default function ResetConfirmation() {
  return (
    <div
      className="h-screen flex flex-col items-center justify-center overflow-scroll"
      style={{
        backgroundColor: "#111312",
      }}
    >
      <div className="mb-4">
        <MintKarmaLogo />
      </div>
      <div
        className="lg:w-1/3 md:w-1/2 w-96 flex items-center justify-center p-4 rounded-lg"
        style={{
          backgroundColor: "#636363",
        }}
      >
        <div className="rounded-md p-2 w-full">
          <div className="flex justify-center">
            <div>
              <MintKarmaPasswordResetLogo />
            </div>
          </div>
          <div className="text-center p-4 text-white">
            <div className="font-bold">Password Reset Successfully</div>
            <div className="text-xs">
              Please login back using your new password
            </div>
          </div>

          <div className="px-3">
            <Button
              className="w-full mb-4"
              style={{ backgroundColor: "#B1EDC4" }}
            >
              Log In
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
