import React, { useEffect, useRef } from "react";
import { motion, useInView, useScroll } from "framer-motion";

const Landing = () => {
  const { scrollYProgress } = useScroll();
  useEffect(() => {
    console.log("scrollYProgress : ", scrollYProgress);
  }, [scrollYProgress]);
  function ComponentView({ children }) {
    const container = useRef(null);
    const ref = useRef(null);
    const isInView = useInView({ root: container });
    useEffect(() => {
      console.log("Element is in view: ", isInView);
    }, [isInView]);
    return (
      <div ref={container} style={{ overflow: "scroll" }}>
        <div ref={ref}>{children}</div>
      </div>
    );
  }

  return (
    <div
      className="bg-blue-400 "
      style={{
        // background: "radial-gradient(circle, #85A99E , #658B7F, #658B7F )",
        background: "radial-gradient(circle, #56C2FF, #3498DB, #1F618D)",
        // height: "100vh",
        // overflowY: "scroll",
        // position: "relative",
        // touchAction: "none",
      }}
    >
      <motion.div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: "10px",
          background: "red",
          transformOrigin: "0%",
          scaleX: scrollYProgress,
        }}
      />
      <div
        style={{
          height: "200vh", // Adjust the height based on the number of sections
          // transition: "all 700ms ease 0s",
          background: "radial-gradient(circle, #56C2FF, #3498DB, #1F618D)",
        }}
      >
        <ComponentView>
          {" "}
          <div
            style={{
              height: "100vh",
              position: "relative",
              backgroundColor: "#ccc",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "2rem",
              // transform: `translate3d(0px, ${-scrollPosition}px, 0px)`,
            }}
          >
            Section 1
          </div>
        </ComponentView>

        <ComponentView>
          <div
            style={{
              height: "100vh",
              position: "relative",
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "2rem",
              // transform: `translate3d(0px, ${-scrollPosition}px, 0px)`,
            }}
          >
            Section 2
          </div>
        </ComponentView>
        {/* Add more sections as needed */}
      </div>
    </div>
  );
};

export default Landing;
