import { Image } from '@nextui-org/react'
import React from 'react'

import DividerIcon from '../Images/MomoKarmaImages/FooterMiddleDot.svg'
export default function Footer() {
  return (
    <div className='bg-[#147AFF] absolute bottom-0 left-0 z-40 w-screen px-4 md:py-5 py-3'>
      <div className='lg:px-10 px-2 text-white lg:container mx-auto lg:text-base text-sm flex md:flex-row flex-col justify-between font-base'>
        <div className='px-4'>© 2024 MomoKarma, All Rights Reserved</div>
        <div className='flex items-center md:gap-4 gap-2 md:mt-0 md:mt-0 mt-1 px-2'>
          <span>Privacy Policy</span>
          <Image
            src={DividerIcon}
            alt="Divider logo"
            width={'4'}
            height={'4'}
          />
          <span>Terms & Conditions</span>
        </div>
      </div>
    </div>

  )
}
