import React from "react";

const MintKarmaPasswordResetLogo = () => {
  return (
    <svg
      width="182"
      height="160"
      viewBox="0 0 182 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Your SVG paths and elements go here */}
      <g id="Password Reset Successfully">
        <g id="Group">
          <g id="Group_2">
            <g id="Group_3">
              <g id="Group_4">
                <g id="Group_5">
                  <path
                    id="Vector"
                    d="M80.9889 135.036C53.9619 135.036 60.9321 139.179 60.9321 139.179C60.9321 139.179 54.8154 139.464 54.8154 142.035C54.8154 144.607 68.4712 147.178 88.5281 147.178C108.585 147.178 109.296 142.893 109.296 142.893C109.296 142.893 130.776 142.893 130.776 138.893C130.776 134.893 99.1966 135.036 80.9889 135.036Z"
                    fill="#0A6B25"
                  />
                  <g id="Group_6" opacity="0.4">
                    <g id="Group_7">
                      <path
                        id="Vector_2"
                        d="M99.3576 141.107C108.579 141.107 116.055 140.037 116.055 138.717C116.055 137.397 108.579 136.328 99.3576 136.328C90.1359 136.328 82.6602 137.397 82.6602 138.717C82.6602 140.037 90.1359 141.107 99.3576 141.107Z"
                        fill="#113A1D"
                      />
                      <path
                        id="Vector_3"
                        d="M78.0174 144.032C83.7321 144.032 88.3648 143.369 88.3648 142.551C88.3648 141.733 83.7321 141.07 78.0174 141.07C72.3026 141.07 67.6699 141.733 67.6699 142.551C67.6699 143.369 72.3026 144.032 78.0174 144.032Z"
                        fill="#113A1D"
                      />
                    </g>
                  </g>
                  <path
                    id="Vector_4"
                    d="M117.658 143.892C106.513 144.556 111.659 147.691 120.737 147.15C129.815 146.609 130.96 143.099 117.658 143.892Z"
                    fill="#0A6B25"
                  />
                </g>
                <g id="Group_8">
                  <path
                    id="Vector_5"
                    d="M124.279 139.551C124.279 139.551 125.334 132.658 129.29 129.992C133.247 127.325 128.788 133.794 128.788 133.794C128.788 133.794 131.777 131.924 132.198 133.717C132.618 135.511 127.816 138.399 124.279 139.551Z"
                    fill="#113A1D"
                  />
                  <path
                    id="Vector_6"
                    d="M65.0106 138.057C65.0106 138.057 64.2161 131.588 60.5935 128.986C56.9709 126.384 60.9578 132.553 60.9578 132.553C60.9578 132.553 58.2163 130.722 57.774 132.386C57.3317 134.05 61.7379 136.882 65.0106 138.057Z"
                    fill="#113A1D"
                  />
                  <path
                    id="Vector_7"
                    d="M118.14 146.267C118.14 146.267 118.388 142.224 121.093 141.084C123.798 139.943 123.705 142.814 118.14 146.267Z"
                    fill="#113A1D"
                  />
                </g>
              </g>
              <g id="Group_9">
                <g id="Group_10">
                  <g id="Group_11">
                    <path
                      id="Vector_8"
                      d="M118.68 104.417L118.681 104.417C118.683 104.418 118.683 104.418 118.683 104.418C121.112 103.991 124.604 103.002 128.68 100.74C132.264 98.7532 134.101 96.7895 134.72 95.2762C135.469 93.4513 134.448 92.2868 132.591 92.5377C132.591 92.5377 125.345 93.9771 119.261 92.5185C118.968 92.4492 118.693 92.4135 118.437 92.4095C118.437 92.4095 118.437 92.4095 118.434 92.4111C118.358 92.43 116.276 93.0274 115.321 95.5047C115.321 95.5066 115.319 95.5081 115.319 95.51C115.023 96.2815 114.835 97.2359 114.853 98.4118C114.924 103.345 118.547 104.381 118.68 104.417Z"
                      fill="url(#paint0_linear_1824_10376)"
                    />
                  </g>
                  <path
                    id="Vector_9"
                    d="M118.679 104.417L118.681 104.417C118.683 104.418 118.683 104.418 118.683 104.418C121.112 103.991 124.604 103.002 128.68 100.74C132.264 98.7532 134.101 96.7896 134.72 95.2762C130.907 97.991 122.419 99.763 115.162 98.4607C115.059 98.4426 114.956 98.4263 114.853 98.4118C114.924 103.345 118.547 104.381 118.679 104.417Z"
                    fill="url(#paint1_linear_1824_10376)"
                  />
                </g>
                <g id="Group_12">
                  <g id="Group_13">
                    <path
                      id="Vector_10"
                      d="M62.3204 116.149C59.6289 116.482 56.7571 113.516 55.2081 110.334C53.9093 107.669 53.538 104.853 54.9719 103.697C56.6765 102.321 60.5364 100.886 63.7175 99.8584C66.4057 98.9897 68.6084 98.4136 68.6084 98.4136L75.9671 109.194C75.9671 109.194 67.2678 115.538 62.3204 116.149Z"
                      fill="url(#paint2_linear_1824_10376)"
                    />
                  </g>
                  <path
                    id="Vector_11"
                    d="M62.3203 116.149C59.6288 116.482 56.757 113.516 55.208 110.334C60.1562 109.226 64.7746 107.12 64.7746 107.12C64.7746 107.12 63.7271 104.738 63.7174 99.8584C66.4056 98.9897 68.6083 98.4136 68.6083 98.4136L75.967 109.194C75.967 109.194 67.2678 115.538 62.3203 116.149Z"
                    fill="url(#paint3_linear_1824_10376)"
                  />
                </g>
                <g id="Group_14">
                  <g id="Group_15">
                    <path
                      id="Vector_12"
                      d="M100.597 129.336C102.394 131.376 106.502 131.062 109.761 129.707C112.491 128.573 114.626 126.709 114.313 124.889C113.943 122.725 111.996 119.083 110.289 116.198C108.847 113.76 107.576 111.864 107.576 111.864L94.9541 115.052C94.9541 115.052 97.2916 125.59 100.597 129.336Z"
                      fill="url(#paint4_linear_1824_10376)"
                    />
                  </g>
                  <path
                    id="Vector_13"
                    d="M100.597 129.336C102.394 131.376 106.502 131.062 109.761 129.707C106.783 125.587 104.71 120.938 104.71 120.938C104.71 120.938 107.065 119.849 110.289 116.198C108.847 113.76 107.576 111.864 107.576 111.864L94.9541 115.052C94.9541 115.052 97.2916 125.59 100.597 129.336Z"
                    fill="url(#paint5_linear_1824_10376)"
                  />
                </g>
                <g id="Group_16">
                  <g id="Group_17">
                    <path
                      id="Vector_14"
                      d="M65.9531 79.3697L65.9521 79.3681C65.9511 79.3665 65.9511 79.3665 65.9511 79.3665C65.3537 76.9646 64.4644 69.699 64.8684 65.0397C65.2218 60.9445 66.2631 58.4614 67.3889 57.2786C68.7456 55.8506 70.2197 56.3143 70.7453 58.1204C70.7453 58.1204 72.3773 65.3551 76.1744 70.3461C76.3564 70.5866 76.5005 70.8244 76.6084 71.0586C76.6084 71.0586 76.6084 71.0586 76.6078 71.0612C76.6215 71.1386 76.9232 73.2921 75.0563 75.174C75.0547 75.1751 75.0541 75.1777 75.0525 75.1787C74.4705 75.7633 73.6782 76.3232 72.6009 76.7844C68.0822 78.7222 66.0401 79.4767 65.9531 79.3697Z"
                      fill="url(#paint6_linear_1824_10376)"
                    />
                  </g>
                  <path
                    id="Vector_15"
                    d="M65.9531 79.3697L65.9521 79.3681C65.9511 79.3665 65.9511 79.3665 65.9511 79.3665C65.3537 76.9646 64.4644 69.699 64.8684 65.0397C65.2218 60.9445 66.2631 58.4614 67.3889 57.2786C66.4661 61.8798 68.2994 70.3889 72.4308 76.5203C72.4891 76.6073 72.5457 76.6953 72.6009 76.7844C68.0822 78.7222 66.0401 79.4767 65.9531 79.3697Z"
                    fill="url(#paint7_linear_1824_10376)"
                  />
                </g>
                <g id="Group_18">
                  <path
                    id="Vector_16"
                    d="M126.303 58.4039C136.666 63.1351 132.883 59.3692 132.883 59.3692C132.883 59.3692 141.135 62.5835 141.792 61.4915C142.435 60.4293 139.681 59.4403 139.545 59.3902C139.736 59.3999 145.219 59.679 145.137 58.1915C145.122 57.9061 144.901 57.5538 144.397 57.1228C141.226 54.4102 135.215 54.5909 135.215 54.5909C135.215 54.5909 138.388 52.6641 134.605 53.4307C131.378 54.0852 127.613 57.2448 126.578 58.1559C126.399 58.3141 126.303 58.4039 126.303 58.4039Z"
                    fill="#113A1D"
                  />
                  <path
                    id="Vector_17"
                    d="M126.303 58.4039C136.666 63.1351 132.883 59.3692 132.883 59.3692C132.883 59.3692 141.135 62.5835 141.792 61.4915C142.435 60.4293 139.681 59.4403 139.545 59.3901C139.736 59.3999 145.219 59.679 145.137 58.1915C142.519 57.4453 135.452 55.9374 126.578 58.1559C126.399 58.3141 126.303 58.4039 126.303 58.4039Z"
                    fill="#042B0F"
                  />
                </g>
                <g id="Group_19">
                  <path
                    id="Vector_18"
                    d="M126.938 58.1667C126.938 58.1667 124.462 67.4896 125.187 68.2909C125.91 69.0914 127.151 67.5675 127.151 67.5675C127.151 67.5675 126.455 75.8021 127.139 76.4413C127.822 77.0806 128.939 74.8496 128.939 74.8496C128.659 78.6124 131.101 84.9565 131.657 85.1387C132.212 85.3209 133.323 82.1571 133.323 82.1571C133.323 82.1571 137.661 91.9468 140.332 91.8292C140.826 91.8094 141.265 91.4464 141.607 90.6151C143.765 85.3703 141.704 76.9832 141.655 76.7885C141.724 76.8812 143.94 79.847 143.675 75.9656C143.407 72.0224 138.51 67.0417 138.51 67.0417C138.51 67.0417 142.055 68.3849 138.891 65.4263C135.73 62.4685 126.938 58.1667 126.938 58.1667Z"
                    fill="#113A1D"
                  />
                  <path
                    id="Vector_19"
                    d="M126.938 58.1667C126.938 58.1667 124.462 67.4896 125.187 68.2909C125.91 69.0914 127.151 67.5675 127.151 67.5675C127.151 67.5675 126.455 75.8021 127.139 76.4413C127.822 77.0806 128.939 74.8496 128.939 74.8496C128.659 78.6124 131.101 84.9565 131.657 85.1387C132.212 85.3209 133.323 82.1571 133.323 82.1571C133.323 82.1571 137.661 91.9468 140.332 91.8292C138.513 73.9263 126.938 58.1667 126.938 58.1667Z"
                    fill="#042B0F"
                  />
                </g>
                <g id="Group_20">
                  <g id="Group_21">
                    <path
                      id="Vector_20"
                      d="M97.5429 121.322C79.9041 121.678 66.7406 109.737 63.9883 95.5805C62.8888 89.9338 63.4499 83.9353 66.0428 78.2227C69.7684 70.0214 77.5509 64.1619 83.8576 60.5291C89.7566 57.1311 96.2912 55.0275 103.046 54.2366C123.717 51.8163 131.525 60.3904 127.772 59.1912C125.273 58.3928 112.39 62.2447 118.843 80.5124C120.216 84.398 121.07 88.4571 121.342 92.4441C122.361 107.197 115.447 120.961 97.5429 121.322Z"
                      fill="url(#paint8_linear_1824_10376)"
                    />
                  </g>
                  <path
                    id="Vector_21"
                    d="M97.5429 121.322C79.9041 121.678 66.7406 109.737 63.9883 95.5805C76.9552 118.814 105.012 117.026 113.086 107.53C118.004 101.744 120.295 96.0771 121.342 92.4441C122.361 107.197 115.447 120.961 97.5429 121.322Z"
                    fill="url(#paint9_linear_1824_10376)"
                  />
                </g>
                <g id="Group_22">
                  <path
                    id="Vector_22"
                    d="M107.393 80.4719C114.558 86.4989 110.981 104.891 99.5243 98.6969C84.8376 90.7572 98.0349 72.6008 107.393 80.4719Z"
                    fill="#FFFEFF"
                  />
                  <path
                    id="Vector_23"
                    d="M86.7846 69.9521C93.5225 73.3207 86.3087 88.4068 76.0232 85.502C65.7377 82.5972 76.5155 64.818 86.7846 69.9521Z"
                    fill="#FFFEFF"
                  />
                  <path
                    id="Vector_24"
                    d="M89.3652 89.1542C89.9549 89.179 90.2595 89.8866 89.8708 90.3325C89.2602 91.0333 88.2132 91.6744 86.5391 91.1758C84.3891 90.5354 83.9294 88.9993 83.9209 87.8266C83.9164 87.2029 84.643 86.8753 85.1112 87.2851C86.4391 88.4476 87.8538 89.0906 89.3652 89.1542Z"
                    fill="black"
                  />
                  <path
                    id="Vector_25"
                    d="M83.5226 81.9813C84.3544 80.9869 84.3642 79.6203 83.5445 78.9287C82.7247 78.2372 81.3858 78.4827 80.554 79.4771C79.7222 80.4714 79.7124 81.8381 80.5321 82.5296C81.3519 83.2212 82.6908 82.9757 83.5226 81.9813Z"
                    fill="black"
                  />
                  <path
                    id="Vector_26"
                    d="M101.198 90.2747C101.7 89.0424 101.284 87.7046 100.268 87.2867C99.2523 86.8688 98.0212 87.5291 97.5186 88.7614C97.0159 89.9937 97.432 91.3315 98.4479 91.7494C99.4638 92.1673 100.695 91.507 101.198 90.2747Z"
                    fill="black"
                  />
                </g>
              </g>
              <g id="Group_23">
                <path
                  id="Vector_27"
                  opacity="0.2"
                  d="M77.7214 54.7738C88.6666 54.7738 97.5394 45.8638 97.5394 34.8726C97.5394 23.8814 88.6666 14.9713 77.7214 14.9713C66.7762 14.9713 57.9033 23.8814 57.9033 34.8726C57.9033 45.8638 66.7762 54.7738 77.7214 54.7738Z"
                  fill="#939DC5"
                />
                <path
                  id="Vector_28"
                  d="M75.5466 52.5898C86.4918 52.5898 95.3646 43.6797 95.3646 32.6885C95.3646 21.6974 86.4918 12.7873 75.5466 12.7873C64.6014 12.7873 55.7285 21.6974 55.7285 32.6885C55.7285 43.6797 64.6014 52.5898 75.5466 52.5898Z"
                  fill="white"
                />
                <path
                  id="Vector_29"
                  d="M75.5468 49.7098C84.908 49.7098 92.4969 42.0891 92.4969 32.6885C92.4969 23.2879 84.908 15.6673 75.5468 15.6673C66.1855 15.6673 58.5967 23.2879 58.5967 32.6885C58.5967 42.0891 66.1855 49.7098 75.5468 49.7098Z"
                  fill="#B1BCED"
                />
                <path
                  id="Vector_30"
                  d="M67.8447 31.2431L73.2041 36.625L83.2479 26.539"
                  stroke="white"
                  stroke-width="8"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_31"
                  d="M75.5472 17.1746C75.5472 17.9136 74.9905 18.5383 74.2577 18.6055C67.5191 19.2227 62.1387 24.6279 61.5241 31.3925C61.4572 32.1284 60.8351 32.6875 60.0992 32.6875C59.2595 32.6875 58.5919 31.9651 58.668 31.1253C59.4069 22.9731 65.8711 16.4795 73.9915 15.7374C74.8278 15.661 75.5472 16.3313 75.5472 17.1746Z"
                  fill="#D8DDF6"
                />
                <path
                  id="Vector_32"
                  opacity="0.5"
                  d="M75.5459 48.2024C75.5459 47.4635 76.1026 46.8387 76.8354 46.7716C83.574 46.1544 88.9545 40.7491 89.569 33.9845C89.6359 33.2486 90.258 32.6896 90.9939 32.6896C91.8337 32.6896 92.5012 33.4119 92.4251 34.2518C91.6862 42.404 85.222 48.8976 77.1016 49.6396C76.2653 49.7161 75.5459 49.0458 75.5459 48.2024Z"
                  fill="#939DC5"
                />
              </g>
              <g id="Group_24">
                <path
                  id="Vector_33"
                  d="M49.7469 36.2052L51.6776 39.7501L55.2077 41.6889L51.6776 43.6277L49.7469 47.1726L47.8163 43.6277L44.2861 41.6889L47.8163 39.7501L49.7469 36.2052Z"
                  fill="#E2CE12"
                />
                <path
                  id="Vector_34"
                  d="M50.9821 52.5898L52.0678 54.5833L54.053 55.6736L52.0678 56.7639L50.9821 58.7575L49.8963 56.7639L47.9111 55.6736L49.8963 54.5833L50.9821 52.5898Z"
                  fill="#E2CE12"
                />
                <path
                  id="Vector_35"
                  d="M110.216 23.6592L111.302 25.6527L113.287 26.743L111.302 27.8333L110.216 29.8269L109.131 27.8333L107.146 26.743L109.131 25.6527L110.216 23.6592Z"
                  fill="#E2CE12"
                />
                <path
                  id="Vector_36"
                  d="M103.931 16.4438L105.315 18.9843L107.845 20.3737L105.315 21.7632L103.931 24.3037L102.547 21.7632L100.018 20.3737L102.547 18.9843L103.931 16.4438Z"
                  fill="#E2CE12"
                />
                <path
                  id="Vector_37"
                  d="M40.5744 45.1344L41.8868 47.5442L44.2865 48.8621L41.8868 50.18L40.5744 52.5898L39.262 50.18L36.8623 48.8621L39.262 47.5442L40.5744 45.1344Z"
                  fill="#E2CE12"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1824_10376"
          x1="128.755"
          y1="104.357"
          x2="121.378"
          y2="90.3554"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B0EDC4" />
          <stop offset="1" stop-color="#E3E4B4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1824_10376"
          x1="128.98"
          y1="104.321"
          x2="113.448"
          y2="80.9661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A8E2BB" />
          <stop offset="1" stop-color="#D8D9AC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1824_10376"
          x1="118.669"
          y1="96.8144"
          x2="57.5682"
          y2="108.746"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B0EDC4" />
          <stop offset="1" stop-color="#E3E4B4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1824_10376"
          x1="117.767"
          y1="96.9918"
          x2="57.9343"
          y2="108.676"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A8E2BB" />
          <stop offset="1" stop-color="#D8D9AC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1824_10376"
          x1="71.1114"
          y1="77.3851"
          x2="109.161"
          y2="126.853"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B0EDC4" />
          <stop offset="1" stop-color="#E3E4B4" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1824_10376"
          x1="71.6699"
          y1="78.1173"
          x2="108.93"
          y2="126.559"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A8E2BB" />
          <stop offset="1" stop-color="#D8D9AC" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1824_10376"
          x1="62.3663"
          y1="74.7542"
          x2="74.8421"
          y2="62.8395"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B0EDC4" />
          <stop offset="1" stop-color="#E3E4B4" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1824_10376"
          x1="61.2866"
          y1="67.416"
          x2="88.8713"
          y2="72.0434"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A8E2BB" />
          <stop offset="1" stop-color="#D8D9AC" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1824_10376"
          x1="87.9138"
          y1="52.7269"
          x2="105.643"
          y2="104.914"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B0EDC4" />
          <stop offset="1" stop-color="#E3E4B4" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1824_10376"
          x1="105.693"
          y1="129.983"
          x2="95.4384"
          y2="102.336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A8E2BB" />
          <stop offset="1" stop-color="#D8D9AC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MintKarmaPasswordResetLogo;
